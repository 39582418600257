import React from 'react';
import { Attributes } from '../modules/IArcgisService'

interface ITestState {
    adata: Attributes[],
    lastUpdate: string
}

export default class Test extends React.Component<{}, ITestState> {

    constructor() {
        super({});
        this.state = {
            //adata: this.getAcrisData().then(),
            adata: [],
            lastUpdate: ""
        };
    }

    componentDidMount() {

        fetch("https://services7.arcgis.com/mOBPykOjAyBO2ZKk/arcgis/rest/services/RKI_Landkreisdaten/FeatureServer/0/query?objectIds=2,3,4,9,10,11,16,207&outFields=OBJECTID,GEN,BEZ,EWZ,cases,cases_per_100k,cases_per_population,last_update,cases7_per_100k,recovered,EWZ_BL,cases7_bl_per_100k&returnGeometry=false&f=json")
            .then(results => results.json())
            .then((results) => {
                const data: Attributes[] = results.features.map((f: { attributes: any; }) => f.attributes);
                console.log(data);
                this.setState({
                    adata: data,
                })
            });
    }

    render() {
        //console.log(this.state.adata.features?.map(f => f.attributes.GEN))
        return (
            <div>
            <table width="80%" border="1" align="center">
                <tr  bgcolor="#555555">
                    <td width="50%">Landkreis</td><td width="25%" align="center">7 Tage Inszidenz</td><td width="25%" align="center">Einwohnerzahl</td>
                </tr>
                {this.state.adata.map((x) => 
                <tr key={x.OBJECTID}>
                    <td>{x.GEN}</td><td align="right">{x.cases7_per_100k.toFixed(2)}</td><td align="right">{x.EWZ}</td>
                </tr>)}
            </table>
                <div></div>
            </div>
        )
    };
}