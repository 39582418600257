import React from 'react';
import { createTheme, Fabric, loadTheme, Stack } from '@fluentui/react'
//import { CalloutBasic } from './components/Callouts';
import Test from './components/test';


const myTheme = createTheme({
  palette: {
    themePrimary: '#f29900',
    themeLighterAlt: '#0a0600',
    themeLighter: '#271900',
    themeLight: '#492e00',
    themeTertiary: '#915c00',
    themeSecondary: '#d58700',
    themeDarkAlt: '#f4a318',
    themeDark: '#f5b13b',
    themeDarker: '#f8c56d',
    neutralLighterAlt: '#484848',
    neutralLighter: '#4f4f4f',
    neutralLight: '#5b5b5b',
    neutralQuaternaryAlt: '#636363',
    neutralQuaternary: '#696969',
    neutralTertiaryAlt: '#828282',
    neutralTertiary: '#e4e4e4',
    neutralSecondary: '#e8e8e8',
    neutralPrimaryAlt: '#ededed',
    neutralPrimary: '#d6d6d6',
    neutralDark: '#f6f6f6',
    black: '#fafafa',
    white: '#404040',
  }
});
loadTheme(myTheme);

//ReactDOM.render(<Content />,document.getElementById('content'));
function App() {
  return (
    <div>
      <Fabric applyThemeToBody>
        <Stack>
          <Test/>
        </Stack>
      </Fabric>
    </div>
  );
}

export default App;
